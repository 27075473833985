import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import SEO from '../views/components/SEO';
import MainLayout from '../views/layouts/MainLayout';
import Image from '../views/components/Image';

import './index.styl';

const IndexPage = ({ data, location }) => {
    const { alu, pvc, wood } = data;

    const sections = [
        {
            className: 'color-pvc',
            image: pvc.childImageSharp,
            label: 'PVC',
            url: 'pvc',
        },
        {
            className: 'color-alu',
            image: alu.childImageSharp,
            label: 'Aluminium',
            url: 'aluminium',
        },
        {
            className: 'color-wood',
            image: wood.childImageSharp,
            label: 'Bois',
            url: 'bois',
        },
    ];

    return (
        <MainLayout
            headerOptions={{
                headerTitle: 'Choisissez la matière de vos fenêtres',
            }}
        >
            <SEO
                title='Brico App'
                location={location}
                description='Brico App'
            />
            <div className='content home'>
                {sections.map((section) => (
                    <div
                        key={`home-section-${section.label}`}
                        className='home-section'
                    >
                        <Image
                            alt={`home section ${section.label}`}
                            {...section.image}
                        />
                        <Link
                            to={`/window-type/${section.url}/`}
                            // to={`/section/${section.url}`}
                            className={`section-button ${section.className}`}
                        >
                            Fenêtres <span>{section.label}</span>
                        </Link>
                    </div>
                ))}
            </div>
        </MainLayout>
    );
};

IndexPage.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export default IndexPage;

export const query = graphql`
    query homePictures {
        wood: file(relativePath: { eq: "wood.png" }) {
            childImageSharp {
                gatsbyImageData(width: 640, layout: CONSTRAINED)
            }
        }
        alu: file(relativePath: { eq: "alu.png" }) {
            childImageSharp {
                gatsbyImageData(width: 640, layout: CONSTRAINED)
            }
        }
        pvc: file(relativePath: { eq: "pvc.png" }) {
            childImageSharp {
                gatsbyImageData(width: 640, layout: CONSTRAINED)
            }
        }
    }
`;
